<template>
  <div class="container">
    <div class="row py-5">
      <div class="col-12 text-center">
        Bem vido ao seu primeiro acesso!
        
      </div>
    </div>
  </div>
</template>

<script>

export default {

  data(){
    return{
    }
  },
  
  methods:{
  }
  
}
</script>
<style lang="scss" scoped>

</style>
